import DownloadButton from '../../views/components/DownloadButton.vue'
import config from "../../../config";

export default {
    data() {
        return {
            inboxes: '',
            selectedIndexID: '',
            noteList: [],
            modelNotes: '',
            data: [],
            completedData: [

            ],
            ongoingData: [],
            warehouseData: [
            ],
            thisDayName: '',
            thisDay: '',
            thisMonthName: '',
            thisYear: '',
            openModal: false,

            warehouseOrder: false,
            completedOrder: true,

            CommentedItem: {},
            id: this.$route.params.id,
            merchant_id: JSON.parse(localStorage.getItem('merchant_id')),
            permission_id: JSON.parse((JSON.parse(localStorage.getItem('jsonPayload')))),
            searchTextDeliveryWareHouse:'',
            searchTextDeliveryComplated:'',

        }
    },

    mounted() {
        this.orderList();
        this.endOrderList();
    },
    watch: {
        searchTextDeliveryWareHouse(){
            console.log(this.searchTextDeliveryWareHouse);
            this.endOrderList();
        },
        searchTextDeliveryComplated(){
            console.log(this.searchTextDeliveryComplated);
            this.orderList();
        }
    },
    components: {DownloadButton},

    computed: {},
    destroyed() {


    },

    methods: {
         orderList() {
             const defaultBody = {
                 search: this.searchTextDeliveryComplated
             };
              this.completedData = []
              this.data = []
              this.ongoingData = []
              this.$http.get(`${config.API_BASE}/api/v1/delivery`,{
                  params:defaultBody
              }, this.config).then((response) => {
                  console.log("bakOrder", response.data)
                  this.completedData = response.data;
                  console.log("buuuu", this.completedData)

              });
          },
        endOrderList() {
            const defaultBody = {
                search: this.searchTextDeliveryWareHouse
            };
            this.warehouseData = []
            this.data = []
            this.ongoingData = []
            this.$http.get(`${config.API_BASE}/api/v1/enddelivery`,{
                params:defaultBody
            }, this.config).then((response) => {
                console.log("bakOrder", response.data)
                this.warehouseData = response.data;
                console.log("buuuu", this.warehouseData)

            });
        },

        changeActive() {
            this.activeOrder = true
            this.completedOrder = false
            this.ongoingOrder = false
            this.warehouseOrder = false
        },
        changeCompleted() {
            this.activeOrder = false
            this.ongoingOrder = false
            this.completedOrder = true
            this.warehouseOrder = false
            this.orderList();
        },
        changeWarehouse() {
            this.activeOrder = false
            this.ongoingOrder = false
            this.completedOrder = false
            this.warehouseOrder = true
            this.endOrderList();
        },
        changeOngoing() {
            this.activeOrder = false
            this.completedOrder = false
            this.ongoingOrder = true
            this.warehouseOrder = false
        },
        approveButton(item, i) {

            console.log(item.inboxes)
                console.log("gitti", item.ProductID, item.inboxes,item.IndexID)

                const formData = new FormData();
                formData.append("IndexID", item.IndexID);
                formData.append("WasDelivered", 1);
                this.$http.post(`${config.API_BASE}/api/v1/delivery`, formData, this.config).then((response) => {


                    setTimeout(() => {
                        this.openModal = false
                        this.endOrderList();
                        this.$vs.notify({
                            color: 'success',
                            title: 'Success',
                            text: 'Success.'
                        })
                        this.orderList();
                    }, 1000);
                });

        },
        notes(item) {
            console.log("bunun", item)
            this.CommentedItem = item
            this.selectedIndexID = item.IndexID
            this.openModal = true
            this.$http.get(`${config.API_BASE}/api/v1/ordernote/${this.selectedIndexID}`, this.config).then((response) => {
                this.noteList = response.data

            });
        },
        addNote() {
            const formData = new FormData();
            formData.append("IndexID", this.selectedIndexID);
            formData.append("Username", localStorage.getItem('user_name'));
            formData.append("Note", this.modelNotes);


            this.$http.post(`${config.API_BASE}/api/v1/ordernote`, formData, this.config).then((response) => {

                this.$http.get(`${config.API_BASE}/api/v1/ordernote/${this.selectedIndexID}`, this.config).then((response) => {
                    this.noteList = response.data
                    this.modelNotes = ''
                });

            });
        },
        closeModal() {
            this.openModal = false
            this.modelNotes = ''
            /*   this.orderList();*/
        }

    }
}
